import {
  LOAD_ALL_GAMES,
  ERROR_LOADING_GAMES,
  LOAD_MORE_GAMES,
  LOADED_GAMES,
  LOADED_MORE_GAMES,
  LOADING_GAMES,
  LOADING_MORE_GAMES,
  ERROR_LOADING_GAME,
  LOAD_GAME,
  LOADED_GAME,
  LOADING_GAME,
} from './actions'
import {
  CreateGame,
  DeleteGame,
  GetAllGames,
  LoadMoreGames,
  GetGame,
} from './effects'

/**
 * Defines a reducer used for controlling an games state
 * @param {import("./defaultState").GamesState} state
 * @param {import('./actions').GamesStateAction} action
 * @param {(effect: import('use-effect-reducer').EffectObject<import('./defaultState').GamesState, import('./actions').GamesStateAction>) => void} exec
 * @returns {import("Hooks/useBookmarks/_defaultState").GamesState} state
 */
export default function GamesReducer(state, action, exec) {
  const actionsMap = {
    // initial load
    [LOAD_ALL_GAMES]: () => {
      if (!state.loadingGames) {
        exec({ type: GetAllGames });
      }

      return state;
    },
    [LOADING_GAMES]: () => {
      return {
        ...state,
        loadingGames: true,
      };
    },
    [LOADED_GAMES]: () => {
      return {
        ...state,
        gamesCount: action.count,
        games: [...action.games],
        loadingGames: false,
      };
    },
    [ERROR_LOADING_GAMES]: () => {
      console.error(action.serverError, 'ERROR_LOADING_GAMES');
      return {
        ...state,
        loadingGames: false,
        serverError: action.serverError,
      };
    },
    [LOAD_GAME]: () => {
      const {gameId} = action;
      if (!state.loadingGame) {
        exec({type: GetGame, gameId})
      }
      return state;
    },
    [LOADING_GAME]: () => {
      return {
        ...state,
        loadingGame: true,
      };
    },
    [LOADED_GAME]: () => {
      const {game: newGame} = action;
      return {
        ...state,
        game: newGame,
        loadingGame: false,
      };
    },
    [ERROR_LOADING_GAME]: () => {
      console.error(action.serverError, 'ERROR_LOADING_GAME');
      return {
        ...state,
        loadingGame: false,
        serverError: action.serverError,
      };
    },

    // pagination events
    [LOAD_MORE_GAMES]: () => {

    },
    [LOADING_MORE_GAMES]: () => {

    },
    [LOADED_MORE_GAMES]: () => {

    }
  };

  const defaultAction = () => {
    return { ...state };
  }

  return actionsMap[action.type]? actionsMap[action.type]() : defaultAction()
}
