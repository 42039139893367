import React, { useContext } from 'react';
import defaultState from './defaultState';
import useGamesStateReducer from './state';
import PropTypes from 'prop-types';

export const GamesStateContext = React.createContext({
  gamesState: defaultState,
  /**
   * Dispatch function dummy default
   * @param {import('Hooks/useBookmarks/actions').GamesStateAction} action
   */
  gamesDispatch: action => {
    console.log(action);
  },
});
export const GamesStateConsumer = GamesStateContext.Consumer;
const useGamesState = () => useContext(GamesStateContext);

const GamesStateProvider = props => {
  const { children, limit, start, loadCounts } = props;

  const { gamesState, gamesDispatch } = useGamesStateReducer({
    limit,
    start,
    loadCounts,
  });

  return (
    <GamesStateContext.Provider value={{ gamesState, gamesDispatch }}>
      {children}
    </GamesStateContext.Provider>
  );
}

GamesStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  limit: PropTypes.number,
  loadCounts: PropTypes.bool,
  start: PropTypes.number,
};

GamesStateProvider.defaultProps = {
  limit: 20,
  start: 0,
  loadCounts: false,
};

export { useGamesState, GamesStateProvider };
