/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import PresentationView from './views/Presentation';
import CryptoJs from 'crypto-js';
import { salt } from './views/Login/components/LoginForm/LoginForm';


const checkAuth = () => {
  const encrytpedObject = localStorage.getItem('Xtoken');
  if (!encrytpedObject) {
    return false
  }
  const decryptedData = JSON.parse(CryptoJs.AES.decrypt(encrytpedObject, salt).toString(CryptoJs.enc.Utf8))
  const now = new Date().getTime();
  return decryptedData.expireAt >= now;
}

const dashboardLayoutRoutes = [
  {
    path: '/carfind-live',
    exact: true,
    component: lazy(() => import('views/CarfindLive'))
  },
  {
    path: '/banners',
    exact: true,
    component: lazy(() => import('views/Banners'))
  },
  {
    path: '/callback-requests',
    exact: true,
    component: lazy(() => import('views/CallbackRequests')),
  },
  {
    path: '/catalogue',
    exact: true,
    component: lazy(() => import('views/Catalogue')),
  },
  {
    path: '/social-feed',
    exact: true,
    component: lazy(() => import('views/SocialFeed'))
  },
  {
    path: '/calendar',
    exact: true,
    component: lazy(() => import('views/Calendar'))
  },
  {
    path: '/changelog',
    exact: true,
    component: lazy(() => import('views/Changelog'))
  },
  {
    path: '/chat',
    exact: true,
    component: lazy(() => import('views/Chat'))
  },
  {
    path: '/chat/:id',
    exact: true,
    component: lazy(() => import('views/Chat'))
  },
  {
    path: '/dashboards/analytics',
    exact: true,
    component: DashboardAnalyticsView
  },
  {
    path: '/dashboards/default',
    exact: true,
    component: DashboardDefaultView
  },
  {
    path: '/invoices/:id',
    exact: true,
    component: lazy(() => import('views/InvoiceDetails'))
  },
  {
    path: '/kanban-board',
    exact: true,
    component: lazy(() => import('views/KanbanBoard'))
  },
  {
    path: '/mail',
    exact: true,
    component: lazy(() => import('views/Mail'))
  },
  {
    path: '/management/customers',
    exact: true,
    component: lazy(() => import('views/CustomerManagementList'))
  },
  {
    path: '/management/customers/:id',
    exact: true,
    component: lazy(() => import('views/CustomerManagementDetails'))
  },
  {
    path: '/management/customers/:id/:tab',
    exact: true,
    component: lazy(() => import('views/CustomerManagementDetails'))
  },
  {
    path: '/management/projects',
    exact: true,
    component: lazy(() => import('views/ProjectManagementList'))
  },
  {
    path: '/management/orders',
    exact: true,
    component: lazy(() => import('views/OrderManagementList'))
  },
  {
    path: '/management/orders/:id',
    exact: true,
    component: lazy(() => import('views/OrderManagementDetails'))
  },
  {
    path: '/overview',
    exact: true,
    component: OverviewView
  },
  {
    path: '/presentation',
    exact: true,
    component: PresentationView
  },
  {
    path: '/profile/:id',
    exact: true,
    component: lazy(() => import('views/Profile'))
  },
  {
    path: '/profile/:id/:tab',
    exact: true,
    component: lazy(() => import('views/Profile'))
  },
  {
    path: '/projects/create',
    exact: true,
    component: lazy(() => import('views/ProjectCreate'))
  },
  {
    path: '/projects/:id',
    exact: true,
    component: lazy(() => import('views/ProjectDetails'))
  },
  {
    path: '/projects/:id/:tab',
    exact: true,
    component: lazy(() => import('views/ProjectDetails'))
  },
  {
    path: '/projects',
    exact: true,
    component: lazy(() => import('views/ProjectList'))
  },
  {
    path: '/settings',
    exact: true,
    component: lazy(() => import('views/Settings'))
  },
  {
    path: '/settings/:tab',
    exact: true,
    component: lazy(() => import('views/Settings'))
  },
  {
    path: '/getting-started',
    exact: true,
    component: lazy(() => import('views/GettingStarted'))
  },
  {
    component: () => <Redirect to="/errors/error-404" />
  }
]

const routes = [
  {
    path: '/',
    exact: true,
    component: () => checkAuth() ? <Redirect to="/catalogue" /> : <Redirect to="/auth/login" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: () => checkAuth() ? <DashboardLayout routes={dashboardLayoutRoutes}/> : <Redirect to="/auth/login" />,
    routes: dashboardLayoutRoutes
  }
];

export default routes;
