import defaultState from './defaultState';
import { useEffectReducer } from 'use-effect-reducer';
import reducer from './reducer';
import getGameEffectsMap from './effects'

/**
 * Initializes state for the reducer
 * @param {object} options Options
 * @param {number} options.limit
 * @param {number} options.start
 * @returns {(exec: function) => import('./defaultState').GamesState}
 */

function init({ limit, start }) {
  return () => {
    return {
      ...defaultState,
      limit,
      start,
    };
  };
}

/**
 * Hook providing methods for manipulating user bookmarks
 * @param {object} options
 * @param {number} [options.limit=20]
 * @param {number} [options.start=0]
 * @param {boolean} [options.loadCounts=false]
 */
export default function BookmarksState(options) {
  const { start = 0, limit = 20 } = options;

  const reducerOptions = { start, limit };
  const [gamesState, gamesDispatch] = useEffectReducer(
    reducer,
    init(reducerOptions),
    getGameEffectsMap(),
  );

  return { gamesState, gamesDispatch };
}
