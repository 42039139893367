import {
  ERROR_LOADING_GAME,
  ERROR_LOADING_GAMES,
  LOADED_GAME,
  LOADED_GAMES,
  LOADING_GAME,
  LOADING_GAMES
} from './actions';
import axios from 'axios';

/**
 * All the possible effects
 *
 * @typedef {LoadEffects|GetAllEffects|CreateEffects|DeleteEffects|GetEffects} GamesStateEffect
 *
 * @typedef {LoadMoreGamesEffect} LoadEffects
 * @typedef {GetAllGamesEffect} GetAllEffects
 * @typedef {CreateGameEffect} CreateEffects
 * @typedef {DeleteGameEffect} DeleteEffects
 * @typedef {GetGameEffect} GetEffects
 */

/**
 * @typedef {object} LoadMoreGamesEffect
 * @property {'LoadMoreGames'} type
 * @property {import("./defaultState").Game} newGame
 * @property {(games?: import("./defaultState").Game, error?: boolean) => void} [onComplete]
 */
export const LoadMoreGames = 'LoadMoreGames';

/**
 * @typedef {object} GetAllGamesEffect
 * @property {'GetGames'} type
 * @property {import("./defaultState").Game} newGame
 * @property {(games?: import("./defaultState").Game, error?: boolean) => void} [onComplete]
 */
export const GetAllGames = 'GetAllGames';

/**
 * @typedef {object} GetGameEffect
 * @property {'GetGames'} type
 * @property {import("./defaultState").Game} newGame
 * @property {(game: import("./defaultState").Game, error?: boolean) => void} [onComplete]
 */
export const GetGame = 'GetGame';

/**
 * @typedef {object} CreateGameEffect
 * @property {'CreateGame'} type
 * @property {number} tempId
 * @property {import("./defaultState").Game} newGame
 * @property {(createdGame?: import("./defaultState").Game, error?: boolean) => void} [onComplete]
 */
export const CreateGame = 'CreateGame';

/**
 * @typedef {object} DeleteGameEffect
 * @property {'DeleteGame'} type
 * @property {number} idx
 * @property {number} itemIdx
 * @property {import("./defaultState").Game} deletedGame
 */
export const DeleteGame = 'DeleteGame';


const getGameEffectsMap = () => {

  return {
    /**
     * Api effect actually gets reports from the Tools API
     * @param {import('./defaultState').GamesState} state
     * @param {import('./effects').GetAllGamesEffect} effect
     * @param {(action: import('./actions').GamesStateAction) => void} dispatch
     */
    [GetAllGames]: (state, effect, dispatch) => {
      dispatch({ type: LOADING_GAMES });
      axios.get('http://localhost:8080/api/v0.6/games/list/')
        .then(response => {
          const { data: games } = response;
          dispatch({
            type: LOADED_GAMES,
            games: games.data,
            ...games
          });
        })
        .catch((err) => {
          dispatch({
            type: ERROR_LOADING_GAMES,
            serverError: err,
          });
        })
    },

    /**
     * Api effect actually gets reports from the Tools API
     * @param {import('./defaultState').GamesState} state
     * @param {import('./effects').GetGameEffect} effect
     * @param {(action: import('./actions').GamesStateAction) => void} dispatch
     */
    [GetGame]: (state, effect, dispatch) => {
      dispatch({type: LOADING_GAME});
      const {gameId} = effect;
      axios.get(`http://localhost:8080/api/v0.6/games/${gameId}`)
        .then(response => {
          const { data } = response;
          dispatch({
            type: LOADED_GAME,
            game: {...data}
          })
        })
        .catch((err) => {
          dispatch({
            type: ERROR_LOADING_GAME,
            serverError: err
          })
        })
    }
  }
};

export default getGameEffectsMap;
