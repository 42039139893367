/**
 * The bookmarks state reducer action types
 * 	@typedef {GameActions} GamesStateAction
 *
 * Mini groups
 * @typedef {
 * ErrorLoadingGamesAction |
 * LoadedMoreGamesAction |
 * LoadedGamesAction |
 * LoadingGamesAction |
 * LoadMoreGamesAction |
 * LoadingMoreGamesAction |
 * GetGamesAction |
 * GetGameAction |
 * LoadingGameAction |
 * LoadedGameAction |
 * ErrorLoadingGameAction
 * } GameActions
 */


/**
 * @typedef {object} GetGameAction
 * @property {'LOAD_GAME'} type
 * @property {string} gameId
 */
export const LOAD_GAME = 'LOAD_GAME'

/**
 * @typedef {object} LoadingGameAction
 * @property {'LOADING_GAME'} type
 */
export const LOADING_GAME = 'LOADING_GAME'

/**
 * @typedef {object} LoadedGameAction
 * @property {'LOADED_GAME'} type
 * @property {object} game
 */
export const LOADED_GAME = 'LOADED_GAME'

/**
 * @typedef {object} ErrorLoadingGameAction
 * @property {'ERROR_LOADING_GAME'} type
 * @property {object} serverError
 */
export const ERROR_LOADING_GAME = 'ERROR_LOADING_GAME'

/**
 * @typedef {object} GetGamesAction
 * @property {'LOAD_ALL_GAMES'} type
 * @property {boolean} more
 * @property {string} [filterType]
 */
export const LOAD_ALL_GAMES = 'LOAD_ALL_GAMES'

/**
 * @typedef {object} LoadingGamesAction
 * @property {'LOADING_GAMES'} type
 */
export const LOADING_GAMES = 'LOADING_GAMES';

/**
 * @typedef {object} LoadedGamesAction
 * @property {'LOADED_GAMES'} type
 * @property {import("./defaultState").Game[]} [games]
 * @property {number} [gameCount]
 */
export const LOADED_GAMES = 'LOADED_GAMES';

/**
 * @typedef {object} LoadMoreGamesAction
 * @property {'LOAD_MORE_GAMES'} type
 * @property {string} [filterType]
 */
export const LOAD_MORE_GAMES = 'LOAD_MORE_GAMES';

/**
 * @typedef {object} LoadingMoreGamesAction
 * @property {'LOADING_MORE_GAMES'} type
 */
export const LOADING_MORE_GAMES = 'LOADING_MORE_GAMES';

/**
 * @typedef {object} LoadedMoreGamesAction
 * @property {'LOADED_MORE_GAMES'} type
 * @property {import("./defaultState").Game[]} [moreGames]
 */
export const LOADED_MORE_GAMES = 'LOADED_MORE_GAMES';

/**
 * @typedef {object} ErrorLoadingGamesAction
 * @property {'ERROR_LOADING_GAMES'} type
 * @property {object} serverError
 */
export const ERROR_LOADING_GAMES = 'ERROR_LOADING_GAMES';
