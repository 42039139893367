import React, { Fragment, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  colors
} from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import bytesToSize from 'utils/bytesToSize';
import axios from '../../utils/axios';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 130
  },
  imageCarPreview: {
    width: 50,
    height: 'auto',
    objectFit: 'cover',
    marginRight: 10
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const FilesDropzone = props => {
  const { className, onFilesDrop, files: currentImageFiles, onFilesDelete, title, labelText, ...rest } = props;

  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const handleDrop = useCallback(acceptedFiles => {
    const updatedFilesArray = [
      ...files,
      ...acceptedFiles
    ];
    setFiles(updatedFilesArray);
    onFilesDrop(updatedFilesArray);
  }, [files]);

  const handleRemoveAll = (existing) => {
    if (existing) {
      setDeletedFiles(existingFiles.map(f => f.name));
      setExistingFiles([]);
    } else {
      setFiles([]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  const deleteFile = useCallback((index, exists) => {
    if (exists) {
      const deletedFile = existingFiles[index];
      const updatedExistingFiles = [...existingFiles];
      updatedExistingFiles.splice(index, 1)
      console.log({existingFiles, updatedExistingFiles, deletedFile}, 'updates');
      setDeletedFiles([
        ...deletedFiles,
        deletedFile.name
      ]);
      onFilesDelete([
        ...deletedFiles,
        deletedFile.name
      ]);
      setExistingFiles(updatedExistingFiles)
    } else {
      const updatedArray = [...files];
      updatedArray.splice(index, 1);
      setFiles(updatedArray);
      onFilesDrop({
        target: {
          value: updatedArray,
          name: 'images'
        }
      });
    }
  }, [
    files,
    setFiles,
    setDeletedFiles,
    setExistingFiles,
    existingFiles,
    deletedFiles,
    onFilesDelete,
    onFilesDrop
  ]);

  const createListFromFiles = async () => {
    const files = [];
    for (const imageFile of currentImageFiles) {
      await fetch(`https://api.carfind.com.ua/${imageFile}`)
        .then(async response => {
          const buffer = await response.arrayBuffer();
          files.push(new File([buffer], imageFile.split('/')[2], {
            type: response.headers.get('content-type') || 'image/png'
          }))
        });
    }
    setExistingFiles(files);
  }

  useEffect(() => {
    if (currentImageFiles.length && !deletedFiles.length) {
      createListFromFiles();
    }
  }, [currentImageFiles]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src="/images/undraw_add_file2_gvbb.svg"
          />
        </div>
        <div>
          <Typography
            gutterBottom
            variant="h3"
          >
            {title}
          </Typography>
          <Typography
            className={classes.info}
            color="textSecondary"
            variant="body1"
          >
            {labelText}
          </Typography>
        </div>
      </div>
      {files.length > 0 && (
        <Fragment>
          {existingFiles.length > 0 && (
            <Typography
              className={classes.info}
              color="textSecondary"
              variant="body1"
            >
              Нові зображення
            </Typography>
          )}
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem
                  divider={i < files.length - 1}
                  key={uuid()}
                >
                  <ListItemIcon>
                    <img
                      alt={file.name}
                      className={classes.imageCarPreview}
                      src={URL.createObjectURL(file)}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title="Видалити">
                    <IconButton
                      edge="end"
                      onClick={() => {
                        deleteFile(i);
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button
              onClick={handleRemoveAll}
              size="small"
            >
              Видалити всі
            </Button>
          </div>
        </Fragment>
      )}

      {existingFiles.length > 0 && (
        <Fragment>
          <Typography
            className={classes.info}
            color="textSecondary"
            variant="body1"
          >
            Існуючі зображення
          </Typography>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {existingFiles.map((file, i) => (
                <ListItem
                  divider={i < existingFiles.length - 1}
                  key={uuid()}
                >
                  <ListItemIcon>
                    <img
                      alt={file.name}
                      className={classes.imageCarPreview}
                      src={URL.createObjectURL(file)}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title="Видалити">
                    <IconButton
                      edge="end"
                      onClick={() => {
                        deleteFile(i, true);
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button
              onClick={() => handleRemoveAll(true)}
              size="small"
            >
              Видалити всі
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string,
  files: PropTypes.array,
  onFilesDelete: PropTypes.func,
  onFilesDrop: PropTypes.func,
  title: PropTypes.string,
  labelText: PropTypes.string,
};

FilesDropzone.defaultProps = {
  onFilesDrop: () => {
  },
  onFilesDelete: () => {},
  files: [],
  title: 'Вибрати файли',
  labelText: 'Або перетягніть їх сюди'
};

export default FilesDropzone;
