/**
 * A Data Bookmark as expected from the api
 * @typedef {object} Game
 * @property {string} [name]
 * @property {number | Date} [dateCreated]
 */

/**
 * The games state for tools api
 * @typedef {object} GamesState
 *
 * * Generic game type can be (Highlight, report, image, or data)
 * @property {Game[]} games Loaded games
 * @property {number} gameCount Total number of games for user
 * @property {boolean} loadingGames Whether or not games are loading
 * @property {boolean} loadingMoreGames Whether or not it is loading more games for pagination
 * @property {number} loadedGamesCount The page we are on for pagination.
 *  */

export default {
  // Games state
  games: [],
  gameCount: 0,
  loadingGames: false,
  loadingMoreGames: false,
  loadedGamesCount: 0,

  // Single game state
  game: null,
  loadingGame: false,
  
  // Misc
  limit: 100,
  start: 0,
};
